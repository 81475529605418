import { DEVELOPMENT_BASE_URL, PRODUCTION_BASE_URL } from "./constants";

export default class Form {
  constructor() {
    this.getForm();
    this.setForm();
    this.goBack();
    this.sendMail();
  }

  getForm() {
    $(".js-confirm-button").on("click", (event) => {
      const form = JSON.stringify($("#contact-form").serializeArray());

      const developmentUrl = `${DEVELOPMENT_BASE_URL}contact-confirm`;
      const productionUrl = `${PRODUCTION_BASE_URL}contact-confirm`;
      const url = location.href.match(/localhost/)
        ? developmentUrl
        : productionUrl;

      sessionStorage.setItem("form", form);

      const isValid = $("#contact-form")[0].checkValidity();
      const selectValue = $("select[name='inquiry']").val();
      const selectValueCheck =
        selectValue === "その他問い合わせ" ? true : false;
      const messageCheck = $('textarea[name="message"]').val();

      const $textarea = $("textarea[name='message']");

      console.log($textarea.val() === "");

      if (
        (selectValue === "その他問い合わせ" && !$textarea.val() === "") ||
        (selectValue === "その他問い合わせ" && !$textarea.val().match(/\S/g))
      ) {
        $(".js-validation").text("");
        this.validation(form);
        return false;
      }

      if (isValid) {
        console.log("first");
        this.setForm(); // formの値を確認画面で挿入する
        console.log("second");
        location.href = url;
      } else {
        $(".js-validation").text("");
        this.validation(form);
      }
    });
  }

  goBack() {
    $(".js-back-button").on("click", () => {
      $(".js-input-value").removeClass("error");
      window.history.back(-1);
      return false;
    });
  }

  setForm() {
    if (sessionStorage.getItem("form")) {
      const arrayFormData = JSON.parse(sessionStorage.getItem("form"));

      arrayFormData.map((data, index) => {
        $(".js-input-confirm").eq(index).text(data.value).val(data.value);
      });
    }
  }

  sendMail() {
    $(".js-submit-button").on("click", (event) => {
      event.preventDefault();
      const arrayFormData = JSON.parse(sessionStorage.getItem("form"));
      const formData = new FormData();
      arrayFormData.map((data) => {
        formData.append(data.name, data.value);
      });
      const developmentUrl = `${DEVELOPMENT_BASE_URL}contact-complete`;
      console.log("submit called");

      const replacedUrl = document.URL.replace(`\/contact-confirm\/`, "");
      const productionUrl = `${replacedUrl}/contact-complete`;

      const url = location.href.match(/localhost/)
        ? developmentUrl
        : productionUrl;

      // location.href = url;

      $.ajax({
        url: "../assets/api/contact.php",
        type: "POST",
        processData: false,
        contentType: false,
        data: formData,
        success: function () {
          sessionStorage.removeItem("form");
          location.href = url;
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          console.log(XMLHttpRequest.status);
          console.log(textStatus);
          console.log(errorThrown.message);
          console.log("error");
        },
      });
    });
  }

  validation(form) {
    const arrayFormData = JSON.parse(form);

    const valueObj = arrayFormData.reduce((acc, cur) => {
      acc[cur.name] = cur.value;
      return acc;
    }, {});

    if (!valueObj.name || !valueObj.name.match(/\S/g)) {
      $("[data-validation='name']").text("入力されていません");
      $("input[name='name']").addClass("error");
    } else {
      $("input[name='name']").removeClass("error");
    }

    if (!valueObj.email || !valueObj.email.match(/\S/g)) {
      $("[data-validation='email']").text("入力されていません");
      $("input[name='email']").addClass("error");
    } else if (
      !valueObj.email.match(
        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      )
    ) {
      $("[data-validation='email']").text("入力形式が違います");
      $("input[name='email']").addClass("error");
    } else {
      $("input[name='email']").removeClass("error");
    }

    if (!valueObj.tel || !valueObj.tel.match(/\S/g)) {
      $("[data-validation='tel']").text("入力されていません");
      $("input[name='tel']").addClass("error");
    } else if (!valueObj.tel.match(/^\d{11}$/)) {
      $("[data-validation='tel']").text("入力形式が違います");
      $("input[name='tel']").addClass("error");
    } else {
      $("input[name='tel']").removeClass("error");
    }

    if (!$("select[name='inquiry']").val()) {
      $("[data-validation='inquiry']").text("選択されていません");
      $("select[name='inquiry']").addClass("error");
    } else if ($("select[name='inquiry']").val()) {
      $("select[name='inquiry']").removeClass("error");
    }

    if (
      valueObj.inquiry == "その他問い合わせ" &&
      !valueObj.message.match(/\S/g)
    ) {
      $("[data-validation='message']").text("入力されていません");
      $("textarea[name='message']").addClass("error");
    } else {
      $("textarea[name='message']").removeClass("error");
    }

    if (!$(".js-checkbox").prop("checked")) {
      $("[data-validation='checkbox']").text(
        "プライバシーポリシーに同意の上「入力内容の確認」へお進みください。"
      );
    }
  }
}

const urlReplacer = (url, keyword) => {
  if (url.match(/keyword/)) {
    url.replace(keyword, "");

    return url;
  }
};
