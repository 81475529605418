export const navToggle = () => {
  const $navToggleSelector = $(".js-nav-toggle-trigger");
  const $navMenu = $(".js-nav-menu");

  $(".js-page").removeClass("js-scroll-prevent");

  $navToggleSelector.on("click", () => {
    $(".js-page").toggleClass("js-scroll-prevent");
    $navToggleSelector.toggleClass("is-open");
    $navMenu.slideToggle(200);
  });

  $(".js-nav-link").on("click", () => {
    if ($navToggleSelector.hasClass("is-open")) {
      $(".js-page").toggleClass("js-scroll-prevent");
      $navToggleSelector.removeClass("is-open");
      $navMenu.slideToggle(200);
    }
  });
};
