import "../scss/style.scss";

import { swiperConfig } from "./swiperConfig";
import { navToggle } from "./navToggle";
import { smoothScroll } from "./smoothScroll";
import { fadeInUpText } from "./fadeInUpText";

import Form from "./Form";

new Form();

window.WOW = require("wowjs").WOW;

const wowAnimation = () => {
  new WOW({
    boxClass: "wow",
    animateClass: "animated",
    offset: 0,
    mobile: true,
  }).init();
};

const init = () => {
  smoothScroll;
  navToggle();
  fadeInUpText();
  wowAnimation();
  swiperConfig();
};

init();
