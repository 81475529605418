// export const swiperConfig = () => {
//   let swiper;
//   $(window).on("load resize", function () {
//     const windowWidth = $(window).width();
//     if (windowWidth <= 768) {
//       if (swiper) {
//         return;
//       } else {
//         swiper = new Swiper(".swiper-container", {
//           slidesPerView: 1.2,
//           spaceBetween: 10,
//           slidesOffsetAfter: 0,
//         });
//       }
//     } else {
//       if (swiper) {
//         swiper.destroy();
//         swiper = undefined;
//       }
//     }
//   });
// };

export const swiperConfig = () => {
  const swiperSlides = document.getElementsByClassName("swiper-slide");
  const breakPoint = 768;
  let mySwiper;
  let mySwiperBool;

  window.addEventListener(
    "load",
    () => {
      if (breakPoint < window.innerWidth) {
        mySwiperBool = false;
      } else {
        createSwiper();
        mySwiperBool = true;
      }
    },
    false
  );

  window.addEventListener(
    "resize",
    () => {
      if (breakPoint < window.innerWidth && mySwiperBool) {
        mySwiper.destroy(false, true);
        mySwiperBool = false;
      } else if (breakPoint >= window.innerWidth && !mySwiperBool) {
        createSwiper();
        mySwiperBool = true;
      }
    },
    false
  );

  const createSwiper = () => {
    mySwiper = new Swiper(".swiper-container", {
      slideToClickedSlide: true,
      spaceBetween: 10,
      slidesPerView: "auto",
      centeredSlides: false,
    });
  };
};
